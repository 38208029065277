import React from 'react'
import PropTypes from 'prop-types'

import PodcastLink from '../css/PodcastLink'

export default function PodcastEpisode({ title, url }) {
  return (
    <li>
      <PodcastLink href={url}>{title}</PodcastLink>
    </li>
  )
}

PodcastEpisode.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
}
