import styled from 'styled-components'

const PodcastLink = styled.a`
  border-bottom: 0.1rem solid var(--textColor);
  transition: 0.5s all ease-in-out;
  &:hover {
    border-bottom: 0.3rem solid var(--textColor);
  }
`

export default PodcastLink
