import React from 'react'

import Section from '../css/Section'
import Content from '../css/Content'
import Br from '../css/Br'
import List from '../css/List'
import ExternalLink from '../css/ExternalLink'
import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'
import PodcastEpisode from '../components/PodcastEpisode'

const pageTitle = 'Podcasts'

function PodcastsPage(props) {
  return (
    <>
      <FunkyTitle title={pageTitle} />
      <Section>
        <Content>
          Here is a list of podcasts I am listening to during my commute, at the
          gym or any other time I get the chance! I am always interested in
          listening to new stuff so feel free to give me a suggestion.
          <List>
            {/* <li>99% Invisible</li> */}
            <li>Acquired</li>
            {/* <li>Bodega Boys</li> */}
            <li>The Changelog</li>
            <li>Darknet Diaries</li>
            {/* <li>Do By Friday</li> */}
            <li>Freakonomics Radio</li>
            {/* <li>Grumpy Old Geeks</li> */}
            {/* <li>How I Built This</li> */}
            {/* <li>The Indie Hackers Podcast</li> */}
            {/* <li>The Jeselnik &amp; Rosenthal Vanity Podcast</li> */}
            <li>Malicious Life</li>
            <li>Masters of Scale with Reid Hoffman</li>
            {/* <li>Motley Fool Money</li> */}
            <li>No Stupid Questions</li>
            <li>Planet Money</li>
            <li>React Podcast</li>
            <li>React Round Up</li>
            <li>Reply All</li>
            <li>StackStories</li>
            <li>StartUp</li>
            <li>ShopTalk</li>
            <li>Syntax.fm</li>
          </List>
          And quite a few more, that I don’t listen to frequently enough to
          mention...
        </Content>

        <Br />
        <Content>
          And here are some of my favourite episodes -
          <List>
            <PodcastEpisode
              url="https://gimletmedia.com/shows/reply-all/v4he6k"
              title="The Snapchat Thief - Reply All"
            />
            <PodcastEpisode
              url="https://www.artofmanliness.com/articles/gangsters-code-lou-ferrante-interview"
              title="Inside the Gangsters’ Code - Art of Manliness"
            />
            <PodcastEpisode
              url="https://gimletmedia.com/shows/reply-all/6nh3wk"
              title="Long Distance - Reply All"
            />
            <PodcastEpisode
              url="https://gimletmedia.com/shows/reply-all/76h5gl"
              title="Long Distance, Part 2 - Reply All"
            />
            <PodcastEpisode
              url="https://www.acquired.fm/episodes/instagram-revisited-with-emily-white"
              title="Instagram Revisited - Acquired"
            />
            <PodcastEpisode
              url="https://www.npr.org/2019/12/13/788003640/episode-959-things-we-learned-in-2019"
              title="Things We Learned in 2019 - Planet Money"
            />
            <PodcastEpisode
              url="https://www.artofmanliness.com/articles/best-board-games-life-lessons"
              title="What Board Games Teach Us About Life - Art of Manliness"
            />
            <PodcastEpisode
              url="https://darknetdiaries.com/episode/58"
              title="OxyMonser - Darknet Diaries"
            />
            <PodcastEpisode
              url="https://darknetdiaries.com/episode/72"
              title="Bangladesh Bank Heist - Darknet Diaries"
            />
            <PodcastEpisode
              url="https://www.artofmanliness.com/articles/plato-republic-meaning"
              title="What Plato’s Republic Has to Say About Being a Man - Art of Manliness"
            />
            <PodcastEpisode
              url="https://fs.blog/knowledge-project/adam-grant2"
              title="Adam Grant: Rethinking Your Position - The Knowledge Project"
            />
            <PodcastEpisode
              url="https://darknetdiaries.com/episode/102/"
              title="Money Maker - Darknet Diaries"
            />
          </List>
          <Br />
          <p>
            Also, if you listen to The Daily, you have to check{' '}
            <ExternalLink href="https://twitter.com/aaronnemo/status/1198790302238203904">
              this out
            </ExternalLink>{' '}
            !
          </p>
        </Content>
      </Section>
    </>
  )
}

PodcastsPage.Layout = Layout
PodcastsPage.pageTitle = pageTitle

export default PodcastsPage
